import React, { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import bg from "../../assets/images/kids-bg-2.jpg";
import { BsPhone } from "react-icons/bs";
import { TbBrandYoutubeKids } from "react-icons/tb";
import OtpInput from 'react-otp-input';
import { handleMessage, postRequest } from "../../lib/lib";
import '../Css/style.css';



const Login = () => {
  const [loader, setLoader] = useState(false);
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [ButtonDisabled, setButtonDisabled] = useState(false);

  const handleNumInputsChange = (numInputs) => {
    setOtp(numInputs);
    setButtonDisabled(numInputs.length !== 4 || isNaN(numInputs));
  };

  const otpIn = async () => {    

    let msisdn = number;
    if (number==null || number.length < 5) {return toast.info("Enter a valid number");}
    else if (number.charAt(0) === "0") msisdn = number.replace("0", "232");
    else if (number.length<10) msisdn = "232" + number;
    
    let body= {username:msisdn,password:otp,serviceId:3};
    setLoader(true);
    const [e1, data] =await postRequest("login",body);    
    setLoader(false);

    if(e1==null && data !=null) 
    { 
        Cookies.set("sl-orange-msisdn",msisdn, { expires: 30,}); 
        window.location.href = localStorage.getItem("/");      
    }
    else{
          console.log(e1.response.data);
          if(e1.response.data!=null)toast.error(e1.response.data.message);
          else toast.error("Unable to process your request");
    }
  };
  
  return (
      <HomeContainer>
        <ContentContainer>
          <InfoBox>
            <SubInfo>
                <TbBrandYoutubeKids color="#ff8008" size={85} />
                <h3>KIDZ MANIA</h3>
                    <h2>Login to your account</h2>
                    <InputContainer>
                        {/* icon */}
                        <BsPhone size={35} color="gray" />
                        <PrimaryInput
                        onChange={(e) => setNumber(e.target.value)}
                        type="number"
                        size="large"
                        className="input"
                        required
                        placeholder="Enter mobile number"
                        />
                    </InputContainer>

                        <p className="Verify_otp">Enter Password</p>
                        <div className="otp_section">               
                        <OtpInput
                            value={otp}
                            numInputs={4}
                            renderInput={(props, index) => (
                                <input
                                    {...props}
                                    type="tel"  // Use type="tel" to display the numeric keypad on mobile devices
                                    inputMode="numeric"  // Specify the numeric input mode
                                />
                            )}
                            inputStyle="form-control form-otp"
                            onChange={handleNumInputsChange}
                            />
                        </div>

                    <PrimaryButton
                        disabled={loader}
                        size="large"
                        color="green"
                        name="subscribe"
                        onClick={otpIn}
                        type="primary"
                    >Login</PrimaryButton>
            </SubInfo>

          </InfoBox>
        </ContentContainer>
      </HomeContainer>
  )
};

export default Login;

const HomeContainer = styled.div`
  background-image: url(${bg});
  /* linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)); */
  /* background-color: #fff; */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-blend-mode: overlay;
  box-sizing: border-box;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 25px 0 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  width: 70%;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
  }
`;
const InfoBox = styled.div`
  min-width: 60%;
  max-width: 100%;
  width: 500px;
  margin-top: -60px;
  h1 {
    color: black;
    /* font-size: 25px; */
    font-weight: 900;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    text-align: center;
    font-size: 14px;
    padding: 9px;
  }`;
    
const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #ffff;
  border-radius: 4px;
  font-size: 15px;
  margin-bottom: 10px;
  font-family: 700;
  color: gray;
  border: 2px solid grey;

  :active {
    border-color: green;
  }
 
  @media screen and (max-width: 768px) {
    width: 100%;
  }`;

const PrimaryInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  height: 40px;
  border-radius: 6px;
  font-weight: 800;
  padding-left: 10px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const SubInfo = styled.div`
  padding: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  justify-content: center;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(2.1px);
  border: 1px solid rgba(255, 255, 255, 0.09);
  width: 100%;
  h2 {
    color: #05C3DD;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    font-family: "Concert One", "Pacifico";
  }
  h3 {
    color: #f46b45;
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    font-family: 'Skranji', "Concert One";
  }
  .select {
    width: 300px;
    font-family: Teko, robot;
    font-family: 700;
    color: grey;
    text-align: center;
  }
  .pack-select {
    display: grid;
    grid-template-rows: row row;
    grid-gap: 10px;
    margin: 10px;
  }
  .checkbox {
    color: #05C3DD;
    font-family: "Concert One", cursive;
    margin: 5px;
  }
  @media screen and (max-width: 768px) {
    h2 {
      text-align: center;
      font-size: 19px;
    }
    .select {
      width: 100%;
    }
  }`;

const PrimaryButton = styled.button`
  background-color: #05C3DD;
  color: white;
  padding: 7px;
  width: 100%;
  margin: 15px 0;
  border: none;
  outline: none;
  font-size: 15px;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Concert One", Times, serif;
  letter-spacing: 4px;
  font-weight: 700;
  :hover {
    background-color: black;
    color: #fff;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }

`;
