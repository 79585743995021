import styled from "styled-components";
import React, { useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { useState } from "react";


// const ReactPlayer = dynamic(() => import("react-player/lazy"), {
//   loading: () => <p>Loading ...</p>,
//   ssr: false,
// });

const VideoComponent = ({
  videoItem,
  nextVideo,
  serviceName,
  showControls = true,
}) => {
  
  const navigation = useNavigate();
 
  if (videoItem.videoUrl != "")
    return (
      <VideoComponentStyled>
        <ReactPlayer
          url={videoItem.videoUrl}
          playing={true}
          controls={showControls}
          width="100%"
          height="100%"
          style={{ position: "absolute" }}
          progressInterval={200}
          // onStart={() => videoPlaying(videoItem.id)}
          playsinline={true}
          onEnded={() => navigation(`/video/${videoItem.id}`)}
          // light={isAndroid ? false : videoItem.imgurl}
          // onReady={() => sendModal()}
          // config={{
          //   file: {
          //     forceHLS: !isSafari,
          //     forceVideo: true,
          //     forceDASH: true,
          //     hlsOptions: {
          //       autoStartLoad: true,
          //       maxMaxBufferLength: 600,
          //       backBufferLength: Infinity,
          //       maxBufferSize: 60 * 1000 * 1000,
          //       maxBufferHole: 0.5,
          //       attributes: { preload: "auto" },
          //       forceAudio: true,
          //     },
          //   },
          // }}
        />
      </VideoComponentStyled>
    );
};

export default VideoComponent;

const VideoComponentStyled = styled.div`
  min-height: 35vh;
  overflow: hidden;
  background-color: black;
  position: relative;
`;
