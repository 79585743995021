import React, { useState } from 'react'
import styled from 'styled-components'
import { HashLoader } from "react-spinners";
import { loaderHandler } from '../lib/lib';

const Loader = () => {
  const [msg, setMsg] = useState('loading wait...')

  useState(() => {
    loaderHandler(setMsg, 2);
  }, [msg]);

  return (
    <StyledLoader>
        <HashLoader
        color='#36d7b7'
        size={50}
        />
        <h1>{msg}</h1>
    </StyledLoader>
  )
}

export default Loader

const StyledLoader = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0,0,0,0.7);
    h1{
        margin-top: 2px;
        color: white;
        font-family: "pacifica";
    }
`;

