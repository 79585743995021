import React, { useEffect } from "react";
import {  useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

// import UserState from "../context/UserState";

const Layout = (props) => {
  let location = useLocation();

  
  useEffect(() => {
    return () => window.scrollTo({ top: 0});
    
  }, [location]);
  
  return (
    <div>
      {location.pathname.includes("welcome") ? null : <Header />}
      
      <div className="content">{props.children}</div>
      {/* {!location.pathname.includes("playgames") ? <Footer /> : null} */}
    </div>
  );
};

export default Layout;
