import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const ErrorPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(
            "/"
        )
    }, [navigate]);
  return (

    <ErrorPageStyled >
        <h1>Redirecting....</h1>
    </ErrorPageStyled >
  )
}


export default ErrorPage;


const ErrorPageStyled = styled.div`
position: fixed;
height: 100%;
width: 100%;
background: whitesmoke;
top: 0;

`