import axios from "axios"
// localhost:64137
let url="https://osl.kiddzmania.com";
//let url="";

const postRequest = async (action, body) => {
    try {
        const resp = await axios.post(url+"/api/"+action, body);
        const data = await resp.data;
        console.log(data)
        return [null, data];
    } catch (e) {
        return [e]
    }
}
const getRequest = async (action) => {
    try {
        const resp = await axios.get(url+"/api/"+action);
        const data = await resp.data;
        return [null, data];
    } catch (err) {
        console.log(err);
        return [err]
    }
}

const handleMessage = (msg_type, pack) => {
   
    switch (msg_type) {
        case "noSub":
            return "Dear user you are not a subscriber of kidzmania service. To subscribe click below";
        case "sub":
            return `Thank you for your request for kidzmania service ${pack} at ${
                pack === "Daily" ? "2" : "10"
               } leones. We are redirecting you to content`;
        case "exist":
            return "Dear user you are already a subscriber of kidzmania service. You will be redirected to content page."
        default :
            return "";
    }
}

const loaderHandler = async (setLoadermsg, ms) => {
    const msgS = [
        "Wait a sec...",
        "Taking longer then expected...",
        "Almost there..",
    ];

   for (let i = 0; i < msgS.length; i++) {
        setLoadermsg(msgS[i]);
        await sleep(ms)
   }
}

const sleep = async (ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms * 1000)
    })
}

export { postRequest,getRequest, handleMessage, loaderHandler, sleep }