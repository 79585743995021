import React from "react";

const Welcome = () => {
  return (
    <div
      style={{
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1
        style={{
          fontFamily: "monospace",
          letterSpacing: "4px",
          fontSize: "45px",
          fontWeight: "800",
          color: "white",
          textAlign: "center",
        }}
      >
        Welcome To kiddzmania{" "}
      </h1>
    </div>
  );
};

export default Welcome;
