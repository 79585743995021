import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

// import "./App.css";

import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

function App() {
  const navigate = useNavigate();  return <div></div>;
}

export default App;
