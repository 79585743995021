import { Checkbox } from "antd";
import React from "react";
import { TbBrandYoutubeKids } from "react-icons/tb";
import styled from "styled-components";

const Heflow = ({ setNumber, optIn, setPack, pack, uiState, loader }) => {
  const checkBoxHandle = (e) => {
    const { name } = e.target;
    setPack(name);
  };
  if (uiState.sub) {
    return (
      <SubInfo>
        <TbBrandYoutubeKids color="#ff8008" size={85} />
        <h3>KIDZ MANIA</h3>
        <h2>{uiState.message}</h2>
      </SubInfo>
    );
  }
  return (
    <>
      <SubInfo>
        <h2>{uiState.message}</h2>
        <TbBrandYoutubeKids color="#ff8008" size={85} />
        <h3>KIDZ MANIA</h3>
        <React.Fragment>
        <h2>TO SUBSCRIBE CLICK BELLOW</h2>
          {/* SELECTION */}
          <div className="pack-select">
            <Checkbox
              className="checkbox"
              checked={pack === "Daily" ? true : false}
              style={{
                transform: "scale(1.5)",
              }}
              name="Daily"
              onChange={checkBoxHandle}
            >
              Daily pack @2 leones
            </Checkbox>
            {/* WEEKLY */}
            <Checkbox
              className="checkbox"
              style={{
                transform: "scale(1.5)",
              }}
              checked={pack === "Daily" ? false : true}
              name="Weekly"
              onChange={checkBoxHandle}
            >
              Weekly pack @10 leones
            </Checkbox>
          </div>
          <PrimaryButton
            disabled={loader}
            size="large"
            color="green"
            name="subscribe"
            onClick={optIn}
            type="primary"
          >
            SUBSCRIBE
          </PrimaryButton>
        </React.Fragment>
      </SubInfo>
    </>
  );
};

export default Heflow;

const SubInfo = styled.div`
  padding: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  justify-content: center;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(2.1px);
  border: 1px solid rgba(255, 255, 255, 0.09);
  width: 100%;
  h2 {
    color: #05C3DD;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    font-family: "Concert One", "Pacifico";
  }
  h3 {
    color: #f46b45;
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    font-family: 'Skranji', "Concert One";
  }
  .select {
    width: 300px;
    font-family: Teko, robot;
    font-family: 700;
    color: grey;
    text-align: center;
  }
  .pack-select {
    display: grid;
    grid-template-rows: row row;
    grid-gap: 10px;
    margin: 10px;
  }
  .checkbox {
    color: #05C3DD;
    font-family: "Concert One", cursive;
    margin: 5px;
  }
  @media screen and (max-width: 768px) {
    h2 {
      text-align: center;
      font-size: 19px;
    }
    .select {
      width: 100%;
    }
  }
`;

const PrimaryButton = styled.button`
  background-color: #05C3DD;
  color: white;
  padding: 7px;
  width: 100%;
  margin: 15px 0;
  border: none;
  outline: none;
  font-size: 15px;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Concert One", Times, serif;
  letter-spacing: 4px;
  font-weight: 700;
  :hover {
    background-color: black;
    color: #fff;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
