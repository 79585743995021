import React from "react";
import styled from "styled-components";
import { TbBrandYoutubeKids } from "react-icons/tb"
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <HeaderStyled>

      <Link to="/redirect">
        <h1>
          <span><TbBrandYoutubeKids /></span>KIDZ MANIA</h1>
      </Link>
    </HeaderStyled>
  );
};

export default Header;

const HeaderStyled = styled.div`
  min-height: 7vh;
  min-width: 100%;
  background-image: linear-gradient(135deg, #304352 10%, #130cb7 100%);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  h1{
    font-family: 'Pacifico', cursive;
    color: #76b852;
    margin-top: 10px;
    /* font-family: 'Space Mono', monospace; */
    font-weight: 800;
  }
  img {
    transform: scale(5);
    position: absolute;
    transform: translate(-50%, -50%);
    height: 100px;
    margin-top: 30px;
    width: 150px;
  }
  .menu-icon {
    position: absolute;
    left: 10px;
    font-size: 30px;
    font-weight: 800;
    z-index: 10;
  }
`;
